// stores/useScrollStore.js
import { defineStore } from 'pinia';

export const useScrollStore = defineStore('scroll', {
  state: () => ({
    positions: {}
  }),
  actions: {
    savePosition(routeName, position) {
      this.positions[routeName] = position;
    },
    getPosition(routeName) {
      return this.positions[routeName] || 0;
    }
  }
});
