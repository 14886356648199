<template>
  <div>
    <Result />
  </div>
</template>

<script setup>
import Result from '@/components/Result.vue';
import { useSearchStore } from '@/stores/search';
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';

const searchStore = useSearchStore();
const route = useRoute();

onMounted(() => {
  const query = route.query.q;
  if (query) {
    searchStore.fetchSearchResults(query);
  }
});
</script>
