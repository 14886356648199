<template>
  <div class="lapsat-news-container bg-sky-950 p-5" v-if="posts.length > 0">
    <div class="flex items-center justify-between bg-sky-950 bg-opacity-80 rounded-sm px-1">
    <div class="flex items-center">
      <!-- Icon and text grouped together -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6 text-yellow-400 mb-3"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3Z"
        />
      </svg>
      <h2 class="text-2xl font-bold text-yellow-400 mb-3 ml-2">
        Berita 81
      </h2>
    </div>
    <hr class="flex-grow border-t-2 border-yellow-400 mb-3 " />
  </div>

    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
      <div   @click="() => navigateToPost(featuredPost.id)" class="featured-post bg-transparent p-4 relative cursor-pointer select-none">
        <div class="w-full h-64 overflow-hidden rounded-lg"> 
          <img v-if="featuredPost.featured_media_src" :src="featuredPost.featured_media_src" alt="Featured Post image"
            class="absolute inset-0 w-full h-64 object-cover rounded-lg">
          <div v-else class="animate-pulse bg-gray-300  absolute inset-0 w-full h-64  rounded-lg"></div> 
        </div>
        <div 
          class="absolute inset-0 h-64 p-4 rounded-lg bg-gradient-to-t from-black via-transparent to-transparent opacity-90">
        </div>
        <div class="absolute inset-0 h-64 p-4 flex flex-col justify-end">
          <!-- Text is pushed to the bottom by justify-end -->
          <h2 class="text-2xl font-bold text-white mb-2 inline-block">
            <span  v-if="featuredPost.title"class="bg-sky-950 bg-opacity-80 rounded-sm px-1">{{ featuredPost.title }}</span> 
            <span v-else class="blur-text bg-sky-950 bg-opacity-80 rounded-sm px-1">Berita hari ini</span> 
          </h2>
          <p v-if="featuredPost.date" class="mb-2 text-white">{{ featuredPost.date }}</p>
          <p v-else class="blur-text mb-2 text-white">2024-20-24T20:24:00</p>
          <p v-if="featuredPost.date" class="text-white" v-html="truncate(stripTags(featuredPost.content), 100)"></p>
          <p v-else class="blur-text text-white">Jakarta - Selamat datang di SMAN 81 Jakarta. Ini adalah website resmi berisi
              berita dan pengumuman ...</p>
          
        </div>
      </div>

      <div class="news-posts space-y-4">
        <div v-for="(post, index) in otherPosts" :key="post.id" @click="navigateToPost(post.id)"
          class="news-post flex bg-transparent p-4 cursor-pointer select-none">
          <div class="bg-gray-200 relative w-full mr-4 sm:w-2/3 sm:h-auto md:w-1/3 h-24 rounded-sm">
            <!-- Use featured_media_src for the image source -->
            <img v-if="post.featured_media_src" :src="post.featured_media_src" alt="Post image"
              class=" h-24 object-cover mr-4 sm:w-2/3 sm:h-auto md:w-full rounded-sm">
            <div v-else class="animate-pulse bg-gray-300 h-24  sm:w-full sm:h-auto md:w-full rounded-sm"></div>
          </div>
          <div class="text-gray-100 sm:w-1/3  sm:h-auto sm:text-sm md:w-2/3">
            <!-- Directly access the title; no need for .rendered -->
            <h3 v-if="post.id" class="text-lg font-bold mb-1">{{ post.title }}</h3>
            <h3 v-else class="blur-text text-lg font-bold mb-1">Judul Post</h3>
            <p v-if="post.id" class="text-sm mb-1">{{ post.date }}</p>
            <p v-else class="blur-text text-sm mb-1">2024-20-24T20:24:00</p>
            <!-- Remove filters and handle content directly -->
            <p v-if="post.id" v-html="truncate(stripTags(post.content), 100)"></p>
            <p v-else class="blur-text">Jakarta - Selamat datang di SMAN 81 Jakarta. Ini adalah website resmi berisi
              berita dan pengumuman ...</p>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { usePostsStore } from '@/stores/posts';
import { computed } from 'vue';
import { useRouter } from 'vue-router'; 


export default {
  name: 'PostGrid',
  setup() {
    const postsStore = usePostsStore();
    postsStore.fetchPosts(); // Fetch posts when the component is created
    const router = useRouter();

    const posts = computed(() => postsStore.posts);
    const featuredPost = computed(() => posts.value[0] || {});
    const otherPosts = computed(() => posts.value.slice(1));
    const navigateToPost = (postId) => {
  router.push({ name: 'single', params: { id: postId } });
};

    return {
      posts,
      featuredPost,
      otherPosts,
      navigateToPost,
    };
  },
  methods: {
    stripTags(content) {
      return content.replace(/<\/?[^>]+(>|$)/g, "");
    },
    truncate(text, length) {
      if (text.length <= length) {
        return text;
      } else {
        return text.substring(0, length) + '...';
      }
    }
  }
};
</script>

<style scoped>
.flex-grow {
  flex-grow: 1;
  margin-left: 1rem; /* Space between the text and the line */
}

.news-post {
  overflow: hidden;
  /* Ensure the scale transformation does not overflow the card */
  transition: transform 0.2s ease;
  /* Smooth transition for the transform */
}

.news-post:hover {
  transform: scale(1.03);
  /* Slightly increase the size */
}

.news-post img,
.news-post h3,
.news-post p {
  transition: transform 0.3s ease;
}

.news-post:hover img,
.news-post:hover h3,
.news-post:hover p {
  transform: scale(1.03);
}

.blur-text {
  filter: blur(2px);
  /* Adjust the blur effect as needed */
}

.lapsat-news-container {
  max-width: 1200px;
  /* or any maximum width that suits your design */
  margin: 0 auto;
  /* This centers the container if there's space on the sides */
  /* Assuming your build setup allows you to resolve paths from the public folder */
  background-image: url('@/assets/pattern.webp');
  background-size: 100% auto;
  background-position: bottom;
  background-repeat: no-repeat;
}

@media (max-width: 640px) {

  /* Tailwind's default breakpoint for 'sm' */
  .lapsat-news-container {
    background-size: auto 35%;
    /* 100% of the width and 50% of the height */
    background-position: bottom;
    /* Center the background image */
  }
}

.post-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>