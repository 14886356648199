<template>
  <div class="home">
    <HeroComponent/>
    <PostGrid/>
 
  </div>
</template>

<script>
// @ is an alias to /src

import HeroComponent from '@/components/HeroComponent.vue'
import PostGrid from '@/components/PostGrid.vue';
export default {
  name: 'HomeView',
  components: {
   
    HeroComponent,
    PostGrid,
 
}
}
</script>
