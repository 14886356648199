// /stores/posts.js
import { defineStore } from 'pinia';
import axios from 'axios';
//const BASE_URL = 'http://192.168.8.103/wp-json/wp/v2';
const BASE_URL = 'https://new.sman81.sch.id/wordpress/wp-json/wp/v2';
function formatDate(dateString) {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-US', {
    weekday: 'long',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  }).format(date);
}

export const usePostsStore = defineStore('posts', {
  state: () => ({
    posts: Array(4).fill().map(() => ({
      id: null,
      title: '',
      content: '',
      featured_media_src: '', // Put a placeholder or a loader image if you have one
      date: '',
      author: ''
    })),
  }),
  actions: {
    async fetchPostById(postId) {
      try {
        const response = await axios.get(`${BASE_URL}/posts/${postId}`);
        const imgSrcMatchId = response.data.content.rendered.match(/<img .*?src=["'](.*?)["']/);
          const featuredImageSrcId = imgSrcMatchId ? imgSrcMatchId[1] : null;
        const post = {
          
          id: response.data.id,
          title: response.data.title.rendered,
          content: response.data.content.rendered,
          author : response.data.author,
          featured_media_src: featuredImageSrcId,
          date: formatDate(response.data.date),
        };
        this.posts.push(post);
      } catch (error) {
        console.error('Error fetching post by ID:', error);
      }
    },
    async fetchPosts() {
      try {
        const response = await axios.get(`${BASE_URL}/posts?categories=3&per_page=4`);
        const posts = response.data.map((post) => {
          const imgSrcMatch = post.content.rendered.match(/<img .*?src=["'](.*?)["']/);
          const featuredImageSrc = imgSrcMatch ? imgSrcMatch[1] : null;
          return {
            id: post.id, 
            title: post.title.rendered,
            content: post.content.rendered,
            author: post.author,
            featured_media_src: featuredImageSrc,
            date: formatDate(post.date)
          };
        });
        this.posts = posts;
      } catch (error) {
        console.error('There was an error fetching the posts:', error);
      }
    },
  },
});
