import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NotFound from '@/components/NotFound'; 
import SingleView from '../views/SingleView.vue';
import ResultView from '@/views/ResultView.vue';
import { useScrollStore } from '@/stores/scrollstate';




const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/post/:id',
    name: 'single',
    component: SingleView,
    props: true // Enable props passing based on route parameters
  },
  {
    path: '/:pathMatch(.*)*', // Updated catch-all route
    name: 'NotFound',
    component: NotFound,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('@/views/admin/AdminView.vue'),
    // Potentially add child routes for specific admin pages
  },
  {
    path: '/results',
    name: 'results',
    component: ResultView,
    props: route => ({ query: route.query.q }),
  },
  /*
  {
    path: '/admin/edit/:id',
    name: 'EditPost',
    component: () => import('@/views/admin/EditPost.vue'),
    props: true, // This allows the component to receive the `id` param as a prop
  },*/
]
const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // This function should be a property of the router configuration.
    const scrollStore = useScrollStore();
    if (savedPosition) {
      return savedPosition;
    } else {
      const position = scrollStore.getPosition(to.name);
      return { left: 0, top: position };
    }
  },
});

router.beforeEach((to, from) => {
  const scrollStore = useScrollStore();
  scrollStore.savePosition(from.name, window.scrollY);
});

export default router;