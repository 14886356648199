<template>
    <div v-if="post" class="single-post min-h-screen  px-8 py-6 md:px-10 lg:px-20 xl:px-40 2xl:px-60"> 
        <div class="p-8"></div>

  
  <nav aria-label="Breadcrumb">
    <ol class="flex items-center gap-1 text-sm text-gray-600">
      <li>
        <a href="#" class="block transition hover:text-gray-700">
          <span class="sr-only"> Home </span>
  
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
            />
          </svg>
        </a>
      </li>
  
      <li class="rtl:rotate-180">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clip-rule="evenodd"
          />
        </svg>
      </li>
  
      <li>
        <a href="#" class="block transition hover:text-gray-700"> Post </a>
      </li>
  
      <li class="">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clip-rule="evenodd"
          />
        </svg>
      </li>
  
      <li>
        <a href="#" class="block transition hover:text-gray-700"> Berita </a>
      </li>
      <li class="">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clip-rule="evenodd"
          />
        </svg>
      </li>
      <li>
        <a href="#" class="block transition hover:text-gray-700">  {{ post.title.length > 10 ? post.title.substring(0, 10) + '...' : post.title }}</a>
      </li>
    </ol>
  </nav>

        <h2
            class="text-3xl md:text-4xl lg:text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-sky-950 to-blue-900 ">
            {{ post.title }}
        </h2>
        <div class="text-sm text-gray-800 mb-3 flex items-center">
  <span>{{ post.date }}</span>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mx-2">
    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
  </svg>
  <span>
    {{ post.author === 1 ? 'admin' : post.author }}
  </span>
</div>
        <div class="prose lg:prose-xl max-w-none text-gray-800 justified-text" v-html="post.content"></div>
    </div>

    <div v-else class="flex justify-center items-center h-screen">
        <span class="text-lg font-medium">Loading post...</span>
    </div>

</template>
<script>
import { onMounted, computed } from 'vue';
import { usePostsStore } from '@/stores/posts';

export default {
    props: {
        postId: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const postsStore = usePostsStore();
       
        const post = computed(() => {
            const foundPost = postsStore.posts.find(p => p.id === parseInt(props.postId, 10));
            return foundPost;
        });
        onMounted(async () => {
      if (!postsStore.posts.find(p => p.id === parseInt(props.postId, 10))) {
        // Check if the API supports fetching by ID, if not, use fetchPosts
        await postsStore.fetchPostById(parseInt(props.postId, 10)); // Or postsStore.fetchPosts();
      }
    });
        // Ensure the computed property is returned so it can be used in the template
        return {
            post,
        };
    }
};
</script>
<style>
.justified-text {
  text-align: justify;
  hyphens: auto;
  lang: "id";
}
/* Add this to your global styles or within the <style> tag in your single post component */
.prose table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1em;
  margin-bottom: 1em;
}

.prose table th,
.prose table td {
  border: 1px solid #ddd;
  padding: 0.5em 1em;
  text-align: left;
}

.prose table th {
  background-color: #f4f4f4;
}


.prose table tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.prose .table-responsive {
  overflow-x: auto;
}
.no-wrap {
  white-space: nowrap  !important;
}
/* Prevent wrap by arif */
/* Prevent wrapping in the first column */
.tb-col1 td:nth-child(1),
.tb-col1 th:nth-child(1) {
  white-space: nowrap;
}

/* Prevent wrapping in the second column */
.tb-col2 td:nth-child(2),
.tb-col2 th:nth-child(2) {
  white-space: nowrap;
}

/* Prevent wrapping in the third column */
.tb-col3 td:nth-child(3),
.tb-col3 th:nth-child(3) {
  white-space: nowrap;
}

/*  HANYA MENAMPILKAN 20 chars */
/* Truncate text in the second column */
.table-class td:nth-child(2) {
  max-width: 10em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}



</style>
