<template>
  <div class="result-bg">
  <div class="max-w-4/5 mx-auto pt-14"> 
    <div v-if="searchStore.loading">Loading...</div>
    <div v-else-if="searchStore.error">{{ searchStore.error }}</div>
    <div v-else-if="searchStore.results.length === 0" class="p-8 mt-12 min-h-screen">
      <p class="prose lg:prose-xl max-w-none text-center ">kata kunci <span class="bg-red-200 px-2 rounded">{{ route.query.q }}</span> tidak ditemukan.</p>
    </div> 
    <div v-else class="p-8 w-5/6 lg:w-7/12 mx-auto rounded bg-opacity-80 bg-white min-h-screen ">
      <ul>
        <li v-for="result in searchStore.results" :key="result.id" class="mb-4 cursor-pointer select-none"  @click="() => navigateToPost(result.id)">
          <h3 class="font-bold text-lg">{{ result.title.rendered }}</h3>
          <p class="prose lg:prose-xl max-w-none text-gray-800" v-html="highlightKeyword(result.content.rendered, route.query.q)"></p>
          <hr>
        </li>
      </ul>
    </div>
  </div>
</div>
</template>
<script setup>
import { computed } from 'vue';
import { useSearchStore } from '@/stores/search';
import { useRoute, useRouter } from 'vue-router'; 


const searchStore = useSearchStore();
console.log(searchStore);
const route = useRoute();
const router = useRouter();
const navigateToPost = (postId) => {
  router.push({ name: 'single', params: { id: postId.toString() } }); // Ensure postId is a string
};
const searchQuery = computed(() => route.query.q);
function highlightKeyword(htmlText, keyword) {
  console.log('keyword:');  
  console.log(keyword); 
  if (!keyword) return htmlText;

  // Convert HTML to plain text for processing.
  const div = document.createElement('div');
  div.innerHTML = htmlText;
  const plainText = div.textContent || div.innerText || "";

  // Search for the keyword in the plain text.
  const lowerCaseText = plainText.toLowerCase();
  const lowerCaseKeyword = keyword.toLowerCase();
  const index = lowerCaseText.indexOf(lowerCaseKeyword);

  if (index === -1) return htmlText; // If keyword not found, return original HTML text.
  const words = plainText.split(/\s+/);

  // Find the word index for the keyword.
  let wordIndex = words.findIndex(word => word.toLowerCase().includes(lowerCaseKeyword));

  // Calculate the start and end indices to get 10 words before and 10 words after the keyword.
  const start = Math.max(0, wordIndex - 10);
  const end = Math.min(words.length, wordIndex + 11);
  // Reconstruct the context with highlighted keyword.
  const beforeKeyword = words.slice(start, wordIndex).join(' ');
  const afterKeyword = words.slice(wordIndex + 1, end).join(' ');
  const keywordText = words[wordIndex];
  
  const highlightedKeyword = `<span class="bg-blue-100 px-2 rounded"><strong>${keywordText}</strong></span>`;
  let context = `${beforeKeyword} ${highlightedKeyword} ${afterKeyword}`;

  return context;
}

</script>
<style>
.result-bg {
  max-width: 1200px;
  margin: 0 auto;
  background-image: url('@/assets/bg_home.webp');
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
 /* height: 100vh;*/
  /* Full height of the viewport */
}
@media (max-width: 639px) {

  /* Adjust '639px' if you want to target a different breakpoint */
  .result-bg {
    background-image: url('@/assets/bg_home_s.webp');
    background-size: cover;
   
    /* Full screen-height on smaller screens */
  }
}</style>