// /stores/search.js
import { defineStore } from 'pinia';
import axios from 'axios';

export const useSearchStore = defineStore('search', {
  state: () => ({
    results: [],
    isLoading: false,
    error: null,
  }),
  actions: {
    async fetchSearchResults(query) {
      this.isLoading = true;
      this.error = null;
      try {
        const response = await axios.get(`https://new.sman81.sch.id/wordpress/wp-json/wp/v2/posts?search=${query}`); 
        this.results = response.data;
      } catch (error) {
        this.error = 'An error occurred during the search.';
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    }
  }
});
