<template>
    <div class="single-view">
      <SinglePost :postId="$route.params.id" />
    </div>
  </template>
  
  <script>
  import SinglePost from '@/components/SinglePost.vue';
  
  export default {
    name: 'SingleView',
    components: {
      SinglePost,
    }
  }
  </script>
  