<template>
  <div class="relative">
    <div class="hero-bg bg-fixed bg-cover bg-center h-screen w-full">

      <div class="flex items-center justify-center h-full">
        <div class="w-10/12 max-w-md px-2 py-1 bg-white rounded-2xl shadow-lg flex items-center">
          <input v-model="searchQuery" type="text" placeholder="Apa yang ingin anda cari?"
            class="w-full p-1 border bg-opacity-80 rounded-l-xl border-none focus:outline-none focus:ring-none"  @keyup.enter="performSearch">
          <button @click="performSearch" class="w-auto bg-yellow-500 hover:bg-yellow-600 rounded-xl text-white font-bold px-4 py-2">
            Cari
          </button>
        </div>
      </div>

    </div>
    <!-- Your content below the hero section -->
    <div class="content">
      <!-- Rest of the page content goes here -->
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
export default {
  name: 'HeroComponent',
  setup() { 
    const searchQuery = ref('');
    const router = useRouter();
    const performSearch = () => {
      router.push({ name: 'results', query: { q: searchQuery.value } });
    };
    return { searchQuery, performSearch };
  }
}
</script>

<style scoped>
.spacer {
  max-width: 1200px;
  margin: 0 auto;
  /* Remove top margin if there was any */
  width: 100%;
  height: 0;
  /* Initially set to 0 to remove space */
  padding-bottom: calc(100% * (15 / 96));
  /* This will maintain the aspect ratio of the SVG */
  background-image: url('@/assets/wave.svg');
  background-repeat: no-repeat;
  background-position: center bottom;
  /* Adjust to 'center top' if the wave should be at the top */
  background-size: 100%;
  display: block;
}

.hero-bg {
  max-width: 1200px;
  margin: 0 auto;
  background-image: url('@/assets/bg_home.webp');
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
  height: 40vh;
  /* Full height of the viewport */
}


@media (max-width: 639px) {

  /* Adjust '639px' if you want to target a different breakpoint */
  .hero-bg {
    background-image: url('@/assets/bg_home_s.webp');
    background-size: cover;
    height: 50svh;
    /* Full screen-height on smaller screens */
  }
}
</style>